import { GetStaticProps } from "next";
import LandingPage, {
  LandingPageProps,
} from "../components/landing/landing-page";
import sendSystemAlert from "../server/email/system-alert";
import { getProducts } from "./api/plans/get-products";
import { localeFromLangCode } from "../utils/locale-from-header";

export const getStaticProps: GetStaticProps<LandingPageProps> = async (
  props
) => {
  const l = localeFromLangCode(props.params?.lang as string);

  try {
    const { products } = await getProducts();

    return {
      props: {
        ...l,
        products: products,
      },
      revalidate: 86400, // 24 hours
    };
  } catch (error: any) {
    console.error(error);
    await sendSystemAlert(
      "Failed to fetch plans on landing page. " + error?.message
    );
  }

  return {
    props: {
      ...l,
      products: null,
    },
    revalidate: 300, // retry in 5 min
  };
};
//const LandingPage: NextPage<Props> = (props: Props) => {
export default function LocalLandingPage(props: LandingPageProps) {
  return <LandingPage {...props} />;
}
